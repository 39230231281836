import { useLocation } from '@remix-run/react';
import { useEffect, useLayoutEffect } from 'react';
import { ClientOnly } from 'remix-utils/client-only';

import { EnumsPlatform } from '@lp-lib/api-service-client/public';

import {
  AnalyticsProvider,
  useReadAnalyticsGlobalStore,
  useWriteAnalyticsGlobalStore,
} from '../../src/analytics/AnalyticsContext';
import { useAnalytics } from '../../src/analytics/AnalyticsContext';
import { useZoomEnv } from '../../src/components/Zoom/utils';
import config from '../../src/config';

function usePageEventEmitter() {
  const analytics = useAnalytics();
  const location = useLocation();
  const readStore = useReadAnalyticsGlobalStore();
  useEffect(() => {
    analytics?.page({
      searchParams: Object.fromEntries(new URLSearchParams(location.search)),
      platform: readStore('platform'),
    });
  }, [analytics, location.search, location.pathname, readStore]);
}

function StoreSetup() {
  const inZoom = !!useZoomEnv();
  const writeStore = useWriteAnalyticsGlobalStore();
  useLayoutEffect(() => {
    if (!inZoom) return;
    writeStore('platform', EnumsPlatform.PlatformZoom);
  }, [inZoom, writeStore]);
  return null;
}

function AnalyticsSetup() {
  usePageEventEmitter();
  return null;
}

export function PageAnalyticsSetup(props: { children?: React.ReactNode }) {
  return (
    <AnalyticsProvider writeKey={config.segment.writeKey}>
      <ClientOnly>{() => <StoreSetup />}</ClientOnly>
      <AnalyticsSetup />
      {props.children}
    </AnalyticsProvider>
  );
}
